import React from "react"
import { graphql } from "gatsby"
import { Grid } from "grommet"
import { Layout, SEO, App } from "../components"
import { useSize } from "../hooks"
const Page = ({ data }) => {
  const size = useSize()
  const isSmall = size === "small"

  const gridConfig = {
    small: {
      columns: ["auto"],
    },
    other: {
      columns: ["auto", "auto"],
    },
  }
  const gridProps = isSmall ? gridConfig.small : gridConfig.other
  const apps = data.allApps.edges
  return (
    <Layout>
      <SEO title="Home" />
      <Grid rows={["auto"]} gap={{ column: "medium" }} {...gridProps}>
        {apps.map(({ node: { provider, name, picture } }) => (
          <App
            key={provider}
            provider={provider}
            name={name}
            picture={picture}
          ></App>
        ))}
      </Grid>{" "}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    allApps(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          name
          picture
          provider
          description
          id
        }
      }
    }
  }
`
